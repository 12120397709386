import { Route, Routes, BrowserRouter } from "react-router-dom";

import PrivateContainer from "./router/PrivateContainer";
import PublicContainer from "./router/PublicContainer";
import { ROUTES } from "./router";
import AppUrlListener from "./Components/AppUrlListener";

function App() {
  return (
    <>
      <AppUrlListener />
      <BrowserRouter>
        <Routes>
          {ROUTES.map(
            ({ Component, isPrivate, path, tabTitle, isDashboard, children }) => (
              <Route
                exact
                path={path}
                key={path}
                element={
                  isPrivate ? (
                    <PrivateContainer
                      tabTitle={tabTitle}
                      isDashboard={isDashboard}
                    >
                      <Component />
                    </PrivateContainer>
                  ) : (
                    <PublicContainer tabTitle={tabTitle}>
                      <Component />
                    </PublicContainer>
                  )
                }
              >
                {children &&
                  children.map(({ path: childPath, Component: ChildComponent, tabTitle }) => (
                    <Route
                      key={childPath}
                      path={childPath}
                      element={<ChildComponent />}
                    />
                  ))}
              </Route>
            )
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
