"use client";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { confirmResetPassword, resetPassword } from "aws-amplify/auth";
import { toast } from "react-toastify";
import {
  Box,
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Badge,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import FormField from "../../assets/theme/overrides/FormField";
import { PrimaryButton } from "../../assets/theme/overrides/button";

const validationSchema = yup.object().shape({
  resetCode: yup.string().required("Reset code is required"),
  newPassword: yup
    .string()
    .required("New password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one numeric digit, and one special character"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Confirm password must match")
    .required("Confirm password is required"),
});

const initialValues = {
  resetCode: "",
  newPassword: "",
  confirmPassword: "",
};

const Item = styled(Paper)(({ theme }) => ({
  borderRadius: "20px",
  padding: "30px 20px",
  width: "100%",
  maxWidth: "500px",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    width: "260px",
    padding: "24px 12px",
  },
}));

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState();

  const handleForgotPassword = async (values) => {
    try {
      setIsLoading(true);
      setUsername(values.username);
      await resetPassword({ username: values.username });
      toast.success("Reset code sent successfully");
      setIsCodeSent(true);
      setIsLoading(false);
      // Code sent successfully, show success message or redirect
    } catch (error) {
      setIsLoading(false);

      console.log("forgot error: ", error);
      toast.error(error.message || "Something went wrong");
    }
  };

  const handleResetPassword = async (values) => {
    try {
      setIsLoading(true);
      await confirmResetPassword({
        username: username,
        confirmationCode: values.resetCode,
        newPassword: values.newPassword,
      });
      toast.success("Password reset successfully");
      setIsLoading(false);
      setTimeout(() => {
        navigate("/signin");
      }, 1000);
      // Password reset successful, redirect or show success message
    } catch (error) {
      setIsLoading(false);
      console.log("Reseat error: ", error);
      toast.error(error.message || "Something went wrong");
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Item elevation={4} style={{ boxShadow: "none" }}>
          <Box>
            <Badge
              color="secondary"
              sx={{ mt: 0, mb: 2 }}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              <Typography
                sx={{ fontSize: { xs: "35px", md: "35px" }, color: "#5f5e5e" }}
              >
                pra
                <span style={{ color: "#02a1ff" }}>x</span>
                is/ai
              </Typography>
              {/* <Chip
                label="BETA"
                variant="outlined"
                color="primary"
                size="small"
                sx={{
                  color: "#0557FA",
                  fontSize: { xs: "20px", md: "28px" },
                  fontWeight: 500,
                  textTransform: "capitalize",
                  border: "none",
                }}
              /> */}
            </Badge>
          </Box>
          <Box>
            <Typography variant="h5" color="#0376BA">
              Forgot password
            </Typography>
          </Box>
          <Box>
            <Box>
              {!isCodeSent ? (
                <Formik
                  initialValues={{ username: "" }}
                  validationSchema={yup.object().shape({
                    username: yup.string().required("Email is required"),
                  })}
                  onSubmit={handleForgotPassword}
                >
                  <Form>
                    <Box>
                      <FormField
                        name="username"
                        label="Enter Email"
                        type="username"
                      />
                    </Box>
                    <PrimaryButton
                      variant="contained"
                      disabled={isLoading}
                      type="submit"
                      sx={{ width: "100%", mt: "25px" }}
                    >
                      {isLoading ? (
                        <CircularProgress color="inherit" size="20px" />
                      ) : (
                        "Send Reset Code"
                      )}
                    </PrimaryButton>
                  </Form>
                </Formik>
              ) : (
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleResetPassword}
                  name="verification"
                >
                  <Form>
                    <FormField
                      name="resetCode"
                      label="Reset Code"
                      type="resetCode"
                    />
                    <FormField
                      name="newPassword"
                      label="New Password"
                      type="newPassword"
                    />
                    <FormField
                      name="confirmPassword"
                      label="Confirm Password"
                      type="confirmPassword"
                    />
                    <PrimaryButton
                      variant="contained"
                      disabled={isLoading}
                      type="submit"
                      sx={{ width: "100%", mt: "25px" }}
                    >
                      {isLoading ? (
                        <CircularProgress color="inherit" size="20px" />
                      ) : (
                        "Submit"
                      )}
                    </PrimaryButton>
                  </Form>
                </Formik>
              )}
            </Box>
          </Box>
        </Item>
      </Grid>
    </>
  );
}
