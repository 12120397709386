import {
  Avatar,
  Box,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { Icon } from "@iconify/react";
import axios from "axios";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";

import ErrorIcon from "../../assets/Images/error-icon.png";
import DownloadTooltip from "./DownloadTooltip";
import TypingText from "./TypingText";
import EmailListDialog from "./EmailListDialog";
import { ChatLoader } from "./Chat";

function ListMessages({
  matchingData,
  lastMessageRef,
  lastQuestionRef,
  loading,
  setOpenPopup,
  openPopup,
  setLastTyping,
}) {
  const user = useSelector((state) => state.user);

  const [download, setDownload] = useState(false);
  const [downloadIndex, setDownloadIndex] = useState(null);
  const [emailList, setEmailList] = useState([user?.email]);

  const handleLoadingChange = (isLoading) => {
    setDownload(isLoading);
  };

  const handleClose = useCallback(() => {
    setOpenPopup(false);
  }, []);

  const handleFileDownload = async (text, type, index) => {
    try {
      handleLoadingChange(true);
      setDownloadIndex(index);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/res_download`,
        { text, type, emailList },
        {
          responseType: "arraybuffer",
        }
      );

      if (type !== "send") {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const filename = `${Date.now()}.${type}`;

        if (Capacitor.isNativePlatform()) {
          const reader = new FileReader();
          reader.onloadend = async () => {
            const base64data = reader.result.split(",")[1];
            const savedFile = await Filesystem.writeFile({
              path: filename,
              data: base64data,
              directory: Directory.Documents,
            });
            await Share.share({
              url: savedFile.uri,
            });
          };
          reader.readAsDataURL(blob);
        } else {
          saveAs(blob, filename);
        }
      }
    } catch (error) {
      console.error(
        "Error during file download:",
        error.response || error.message
      );
    } finally {
      handleLoadingChange(false);
      setDownloadIndex(null);
    }
  };

  return (
    <>
      {matchingData?.map((message, index) => {
        const lastIndex = matchingData.length - 1;
        const isLastMessage = index === lastIndex;

        return (
          <Box
            key={index}
            ref={isLastMessage ? lastMessageRef : null}
            sx={{ p: { xs: "0px 0px", md: "0px 0px" } }}
          >
            <Box
              sx={{
                // display: "flex",
                alignItems: "start",
                gap: { xs: "10px", sx: "15px", md: "20px" },
                py: { xs: 2, sx: 2, md: 1.5 },
                px: { xs: 2, sx: 3, md: 1.5 },
                borderRadius: "8px",
                maxWidth: {
                  xs: "450px",
                  sm: "540px",
                  md: "800px",
                },
                margin: "0 auto",
              }}
              ref={isLastMessage ? lastQuestionRef : null}
            >
              <Avatar
                alt="John Doe"
                sx={{
                  width: { xs: "25px", md: "25px" },
                  height: { xs: "25px", md: "25px" },
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                  borderRadius: "4px",
                  color: "rgba(0, 0, 0, 0.87)",
                }}
              >
                <PersonOutlineOutlinedIcon />
              </Avatar>
              <Typography
                mt={1}
                variant="body1"
                style={{
                  whiteSpace: "pre-line",
                }}
                sx={{
                  color: "#343541",
                }}
              >
                {message?.question}
              </Typography>
            </Box>

            <Tooltip
              title={
                !message?.isError ? (
                  <DownloadTooltip
                    messageText={message?.answer}
                    index={index}
                    setOpenPopup={setOpenPopup}
                    onLoadingChange={handleLoadingChange}
                    setDownloadIndex={setDownloadIndex}
                    handleFileDownload={handleFileDownload}
                  />
                ) : null
              }
              arrow
              placement="bottom-end"
              PopperProps={{
                disableInteractive: true,
              }}
              sx={{
                "& .MuiTooltip-tooltip": {
                  padding: "8px 8px",
                },
              }}
            >
              {message?.answer && (
                <Box
                  sx={{
                    alignItems: "start",
                    py: { xs: 1, sx: 2, md: 1.5 },
                    px: { xs: 1, sx: 3, md: 1.5 },
                    borderRight: 0,
                    borderLeft: 0,
                    backgroundColor: (theme) => theme.palette.grey[100],
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: {
                        xs: "450px",
                        sm: "540px",
                        md: "800px",
                      },
                      margin: "0 auto",
                    }}
                  >
                    {message?.isError ? (
                      <img
                        alt=""
                        src={ErrorIcon}
                        style={{
                          width: "30px",
                          height: "25px",
                        }}
                      />
                    ) : (
                      <Avatar
                        alt="Ai"
                        sx={{
                          width: "25px",
                          height: "25px",
                          color: "rgba(28, 27, 31, 1)",
                          backgroundColor: "rgba(0, 0, 0, 0.05)",
                          borderRadius: "4px",
                        }}
                      >
                        <Icon icon="material-symbols:robot-2-outline-rounded" />
                      </Avatar>
                    )}
                  </Box>

                  <Box
                    sx={{
                      maxWidth: {
                        xs: "450px",
                        sm: "540px",
                        md: "800px",
                      },
                      margin: "0 auto",
                      color: "#343541",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      lineHeight: 1.5,
                      letterSpacing: "0.00938em",
                      borderBottom: message?.isError ? "2px solid red" : "",
                    }}
                  >
                    <Box className="markdown-body">
                      <TypingText
                        markdownContent={message?.answer}
                        setLastTyping={setLastTyping}
                        shouldType={message?.shouldType}
                      />
                    </Box>
                  </Box>
                </Box>
              )}

              {openPopup && (
                <EmailListDialog
                  onClosePopup={handleClose}
                  openPopup={openPopup}
                  emailList={emailList}
                  setEmailList={setEmailList}
                  messageText={message?.answer}
                  handleFileDownload={handleFileDownload}
                  index={index}
                />
              )}
            </Tooltip>

            <Box
              sx={{
                maxWidth: {
                  xs: "450px",
                  sm: "540px",
                  md: "800px",
                },
                margin: "10px auto",
              }}
            >
              {download && downloadIndex === index && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    width: "100%",
                  }}
                >
                  <Avatar
                    alt="Ai"
                    sx={{
                      width: { xs: "25px", md: "25px" },
                      height: { xs: "25px", md: "25px" },
                      color: "rgba(28, 27, 31, 1)",
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                      borderRadius: "4px",
                    }}
                  >
                    <Icon icon="material-symbols:robot-2-outline-rounded" />
                  </Avatar>
                  <Typography variant="body2">Preparing document...</Typography>
                  <LinearProgress sx={{ width: "40%" }} />
                </Box>
              )}
            </Box>
            {loading === true && isLastMessage && <ChatLoader />}
          </Box>
        );
      })}
    </>
  );
}

export default ListMessages;
