import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  List,
  ListItem,
  ListItemText,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogContentText,
  DialogActions,
  TextField,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setSidebarClose,
  setIsNewChatModel,
  setMyFiles,
  fetchUserChats,
  setAutoScroll,
} from "../redux/reducer";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import history from "../assets/Images/history.png";
import add from "../assets/Images/add.png";

const InsideDrawer = () => {
  const chatHistory = useSelector((state) => state.chatHistory);
  const sidebarClose = useSelector((state) => state.sidebarClose);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editTitle, setEditTitle] = useState("");
  const [selectedData, setSelectedData] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const threadId = queryParams.get("threadId");

  const handleMenuClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedData(data);
  };

  const handleDelete = async (deletedThreadId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/users_chat_history?threadId=${deletedThreadId}`
      );
      dispatch(fetchUserChats());
      handleDialogClose();
      navigate("/dashboard");
    } catch (error) {
      console.error("Error deleting thread:", error);
    }
  };

  const sideBarClose = () => {
    dispatch(setSidebarClose(!sidebarClose));
  };

  const newChat = () => {
    dispatch(setIsNewChatModel(true));
  };

  const handleDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
    handleMenuClose();
  };

  const editThreadTitle = async () => {
    setIsEdit(false);
    const trimmedTitle = editTitle.trim();

    if (!trimmedTitle) {
      return;
    }
    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/update_users_chat_history`,
      {
        threadTitle: editTitle,
        id: selectedData.id,
      }
    );

    dispatch(fetchUserChats());
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "44px",
          padding: "20px 15px 0px 15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "24px",
            height: "24px",
          }}
          onClick={() => sideBarClose()}
        >
          <img
            src={history}
            style={{ objectFit: "cover", width: " 100%" }}
            alt=""
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "24px",
            height: "24px",
          }}
          onClick={() => newChat()}
        >
          <img
            src={add}
            style={{ objectFit: "cover", width: " 100%" }}
            alt=""
          />
        </Box>
      </Box>
      <List sx={{ padding: "15px 0px" }}>
        {chatHistory && Object.keys(chatHistory).length > 0 ? (
          Object.values(chatHistory).map((item) => {
            const history = item[0];
            const isSelected = threadId === history.threadId;
            return (
              <>
                <ListItem
                  key={history.threadId}
                  sx={{
                    borderTop: "1px solid #ddd",
                    cursor: "pointer",
                    backgroundColor: isSelected ? "rgba(0, 0, 0, 0.04)" : "",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    const isMobile = window.innerWidth <= 768;
                    if (isMobile && !isEdit) {
                      dispatch(setSidebarClose(false));
                    }
                    dispatch(setMyFiles([]));
                    dispatch(setAutoScroll(true));
                    navigate(`/dashboard?threadId=${history.threadId}`);
                  }}
                >
                  {isEdit && selectedData?.id === history?.id ? (
                    <TextField
                      value={editTitle}
                      onChange={(e) => setEditTitle(e.target.value)}
                      onBlur={editThreadTitle}
                      onKeyUp={(e) => e.key === "Enter" && editThreadTitle()}
                    />
                  ) : (
                    <ListItemText
                      primary={
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "180px",
                          }}
                        >
                          {history.threadTitle}
                        </div>
                      }
                    />
                  )}
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleMenuClick(event, history);
                    }}
                    sx={{ padding: "0px" }}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                </ListItem>
              </>
            );
          })
        ) : (
          <ListItem>
            <ListItemText
              primary="Chat History"
              sx={{
                textAlign: "center",
                color: "#999",
                fontSize: "16px",
              }}
            />
          </ListItem>
        )}
      </List>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
            minWidth: "100px",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setIsEdit(true);
            // setEditTitle(selectedData?.threadTitle);
            setEditTitle(" ");
            handleMenuClose();
          }}
          sx={{
            gap: 1.5,
            padding: "8px 8px",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
          }}
        >
          <ModeEditIcon sx={{ fontSize: "20px" }} />
          <span style={{ fontWeight: "500", color: "#333" }}>Rename</span>
        </MenuItem>
        <MenuItem
          onClick={() => handleDialogOpen()}
          sx={{
            gap: 1.5,
            padding: "8px 8px",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
          }}
        >
          <DeleteIcon sx={{ color: "error.main", fontSize: "20px" }} />
          <span style={{ fontWeight: "500", color: "#333" }}>Delete</span>
        </MenuItem>
      </Menu>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="confirm-delete-dialog"
        aria-describedby="confirm-delete-description"
      >
        <DialogTitle id="confirm-delete-dialog">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-description">
            Are you sure you want to delete this item? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDelete(selectedData.threadId)}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InsideDrawer;
