import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import { useDispatch, useSelector } from "react-redux";
import {
  setDrawer,
  setIsDashboardDropdownOpen,
  setIsDropdownOpen,
  setPipelineTypeDoc,
  setSelectedAIModel,
} from "../redux/reducer";

export const AIModelList = [
  {
    model: "gpt-3.5-turbo",
    name: "gpt-3.5-turbo",
    provider: "openAI",
    dec: "A fast, inexpensive model for simple tasks",
    allowImage: false,
  },
  {
    model: "gpt-4-turbo",
    name: "gpt-4-turbo",
    provider: "openAI",
    dec: "The previous set of high-intelligence models",
    allowImage: true,
  },
  {
    model: "gpt-4o",
    name: "gpt-4o",
    provider: "openAI",
    dec: "high-intelligence flagship model for complex, multi-step tasks",
    allowImage: true,
  },
  {
    model: "gpt-4o-mini",
    name: "gpt-4o-mini",
    provider: "openAI",
    dec: "affordable and intelligent small model for fast, lightweight tasks",
    allowImage: true,
  },
  {
    model: "o1-preview",
    name: "gpt-o1-preview",
    provider: "openAI",
    dec: "Language models trained with reinforcement learning to perform complex reasoning.",
    allowImage: false,
  },
  // {
  //   model: "o1",
  //   name: "gpt-o1",
  //   provider: "openAI",
  //   dec: "reasoning model designed to solve hard problems across domains",
  // },
  // {
  //   model: "o1-mini",
  //   name: "gpt-o1-mini",
  //   provider: "openAI",
  //   dec: "fast and affordable reasoning model for specialized tasks",
  // },
  {
    model: "us.anthropic.claude-3-5-sonnet-20241022-v2:0",
    name: "Claude 3.5 Sonnet",
    provider: "aws",
    dec: "The upgraded Claude 3.5 Sonnet is now state-of-the-art for a variety of tasks including real-world software engineering, agentic capabilities and computer use.",
    allowImage: true,
  },
  {
    model: "us.meta.llama3-2-90b-instruct-v1:0",
    name: "Llama 3.2",
    provider: "aws",
    dec: "Instruction-tuned image reasoning generative model (text + images in / text out) optimized for visual recognition, image reasoning, captioning and answering general questions about the image.",
    allowImage: false,
  },
  {
    model: "us.meta.llama3-3-70b-instruct-v1:0",
    name: "Llama 3.3",
    provider: "aws",
    dec: "Ability to follow instructions and tasks, improved reasoning and understanding of nuances and context, and multilingual translation.",
    allowImage: false,
  },
  {
    model: "mistral.mistral-large-2402-v1:0",
    name: "Mistral AI large",
    provider: "aws",
    dec: "The most advanced Mistral AI Large Language model capable of handling any language task including complex multilingual reasoning, text understanding, transformation, and code generation.",
    allowImage: false,
  },
  {
    model: "amazon.nova-pro-v1:0",
    name: "Nova Pro",
    provider: "aws",
    dec: "Nova Pro is a multimodal understanding foundation model. It is multilingual and can reason over text, images and videos.",
    allowImage: true,
  },
  {
    model: "amazon.nova-lite-v1:0",
    name: "Nova Lite",
    provider: "aws",
    dec: "Nova Lite is a is a multimodal understanding foundation model. It is multilingual and can reason over text, images and videos.",
    allowImage: true,
  },
  {
    model: "gemini-1.5-pro-002",
    name: "Gemini 1.5 Pro",
    provider: "google",
    dec: "A multimodal model that supports adding image, audio, video, and PDF files in text or chat prompts for a text or code response. This model supports long-context understanding up to the maximum input token limit.",
    allowImage: true,
  },
  {
    model: "gemini-1.5-flash-002",
    name: "Gemini 1.5 Flash",
    provider: "google",
    dec: "Provides speed and efficiency for high-volume, quality, cost-effective apps.",
    allowImage: true,
  },
  {
    model: "gemini-2.0-flash-exp",
    name: "Gemini 2.0 Flash",
    provider: "google",
    dec: "Provides speed and efficiency for high-volume, quality, cost-effective apps.",
    allowImage: true,
  },
  {
    model: "grok-2-1212",
    name: "Grok 2",
    provider: "xai",
    dec: "These models bring improvements in accuracy, instruction-following, and multi-lingual capabilities and offer a great option for developers looking for a highly-steerable and intelligent model.",
    allowImage: true,
  },
];

function AIModelSelect({ openAIAPIStatus, dashboard, sidebar, ...rest }) {
  const selectedAIModel = useSelector((state) => state.selectedAIModel);
  const isDropdownOpen = useSelector((state) => state.isDropdownOpen);
  const isDashboardDropdownOpen = useSelector(
    (state) => state.isDashboardDropdownOpen
  );
  const dispatch = useDispatch();

  const onModelChanges = (e) => {
    const val = e.target.value;
    dispatch(setSelectedAIModel(val));
    if (val.provider === "openAI" && val.model !== "gpt-3.5-turbo") {
      dispatch(setPipelineTypeDoc("openaiFile"));
    } else {
      dispatch(setPipelineTypeDoc("langchain"));
    }
  };

  return (
    <>
      {dashboard === "true" && (
        <Select
          fullWidth
          open={isDashboardDropdownOpen}
          onClose={() => dispatch(setIsDashboardDropdownOpen(false))}
          onOpen={() => dispatch(setIsDashboardDropdownOpen(true))}
          value={selectedAIModel}
          onChange={onModelChanges}
          renderValue={(selected) => (
            <>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize:
                      window.innerWidth <= 320
                        ? "12px"
                        : window.innerWidth <= 435
                        ? "14px"
                        : "16px",
                    maxWidth:
                      window.innerWidth <= 320
                        ? "60px"
                        : window.innerWidth <= 435
                        ? "80px"
                        : "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {selected.provider === "openAI" && (
                    <Badge
                      sx={{ mr: 1, ml: 0.5 }}
                      color={
                        openAIAPIStatus === "none"
                          ? "success"
                          : openAIAPIStatus === "minor"
                          ? "warning"
                          : "error"
                      }
                      variant="dot"
                    />
                  )}

                  {selected.name}
                </span>
              </span>
            </>
          )}
          {...rest}
        >
          {AIModelList.map((item, index) => (
            <MenuItem value={item} key={index}>
              <Tooltip
                title={
                  <span
                    style={{
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                    }}
                  >
                    {item.dec}
                  </span>
                }
                placement="right"
                arrow
                slotProps={{
                  tooltip: {
                    sx: {
                      maxWidth: "80px",
                      padding: "4px 8px",
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                      '&[data-popper-placement*="right"]': {
                        marginLeft: "10px",
                      },
                    },
                  },
                }}
              >
                <>
                  {item.provider === "openAI" && (
                    <Badge
                      sx={{ mr: 1 }}
                      color={
                        openAIAPIStatus === "none"
                          ? "success"
                          : openAIAPIStatus === "minor"
                          ? "warning"
                          : "error"
                      }
                      variant="dot"
                    />
                  )}
                  <span>{item.name}</span>
                </>
              </Tooltip>
            </MenuItem>
          ))}
        </Select>
      )}

      {sidebar == "true" && (
        <Select
          fullWidth
          open={isDropdownOpen}
          onClose={() => dispatch(setIsDropdownOpen(false))}
          onOpen={() => dispatch(setIsDropdownOpen(true))}
          value={selectedAIModel}
          onChange={onModelChanges}
          {...rest}
        >
          {AIModelList.map((item, i) => (
            <MenuItem
              value={item}
              key={i}
              onClick={() => dispatch(setDrawer(false))}
            >
              <Tooltip
                title={
                  <span
                    style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                  >
                    {item.dec}
                  </span>
                }
                placement="right"
                arrow
                slotProps={{
                  tooltip: {
                    sx: {
                      maxWidth: "90px",
                      padding: " 4px 8px",
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                      '&[data-popper-placement*="right"]': {
                        marginLeft: "10px",
                      },
                    },
                  },
                }}
              >
                {item.provider === "openAI" && (
                  <Badge
                    sx={{ mr: 1 }}
                    color={
                      openAIAPIStatus === "none"
                        ? "success"
                        : openAIAPIStatus === "minor"
                        ? "warning"
                        : "error"
                    }
                    variant="dot"
                  />
                )}
                <span>{item.name}</span>
              </Tooltip>
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
}

export default AIModelSelect;
