import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { updateUserAttributes } from "aws-amplify/auth";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

function UpdateGivenName() {
  const user = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (user) {
      if (!user?.given_name) {
        setOpen(true);
      }
    }
  }, [user]);

  const updateName = async (name) => {
    try {
      await updateUserAttributes({
        userAttributes: {
          given_name: name,
        },
      });
      handleClose();
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            updateName(formJson.name);
          },
        }}
      >
        <DialogTitle>Update Your Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            name="name"
            label="Enter a full name"
            type="text"
            fullWidth
          />
        </DialogContent>

        <DialogActions>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UpdateGivenName;
