import { Box, Button, Dialog, Typography } from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";

const EmailListDialog = ({
  onClosePopup,
  openPopup,
  emailList,
  setEmailList,
  handleFileDownload,
  messageText,
  index,
}) => {
  return (
    <Dialog
      onClose={onClosePopup}
      open={openPopup}
      sx={{
        zIndex: 10000,
        "& .MuiDialog-paper": {
          borderRadius: 4,
          p: 3,
          maxWidth: "500px",
          width: "100%",
        },
      }}
    >
      <Box>
        <Typography variant="h6" id="email-dialog-title">
          Send Email To:
        </Typography>
        <Box>
          <MuiChipsInput
            value={emailList}
            helperText={
              emailList.length > 0 ? "Double click to edit a chip" : ""
            }
            onChange={(newChips) => {
              setEmailList(newChips);
            }}
            sx={{ width: "100%" }}
            placeholder="Add recipient emails"
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              handleFileDownload(messageText, "send", index);
            }}
            sx={{
              borderRadius: 2,
              textTransform: "none",
              px: 3,
              py: 1,
              bgcolor: "primary.main",
              "&:hover": {
                bgcolor: "primary.dark",
              },
            }}
          >
            Send Email
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default EmailListDialog;
