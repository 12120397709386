import React, { useState, useRef, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import MicIcon from "@mui/icons-material/Mic";
import { IconButton } from "@mui/material";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { KeepAwake } from "@capacitor-community/keep-awake";

const keepAwake = async () => {
  const result = await KeepAwake.isSupported();
  if (result.isSupported) {
    await KeepAwake.keepAwake();
  }
};

const allowSleep = async () => {
  await KeepAwake.allowSleep();
};

const VoiceRecorder = ({ isVoiceAgent, setIsVoiceAgent }) => {
  const [isSessionActive, setIsSessionActive] = useState(false);
  const [events, setEvents] = useState([]);
  const [functionAdded, setFunctionAdded] = useState(false);
  const [dataChannel, setDataChannel] = useState(null);
  const peerConnection = useRef(null);
  const audioElement = useRef(null);

  const typeOfAg = useSelector((state) => state.typeOfAg);
  const selectedAIModel = useSelector((state) => state.selectedAIModel);
  const user = useSelector((state) => state.user);
  const isRFormate = useSelector((state) => state.isRFormate);
  const temperature = useSelector((state) => state.temperature);
  const pipelineTypeDoc = useSelector((state) => state.pipelineTypeDoc);
  const flowPipeline = useSelector((state) => state.flowPipeline);
  const vectorTypeDB = useSelector((state) => state.vectorTypeDB);
  const myFiles = useSelector((state) => state.myFiles);

  useEffect(() => {
    keepAwake();
    startSession();

    return () => {
      handleClose();
    };
  }, []);

  async function startSession() {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/openAI_realtime_token`
    );
    const EPHEMERAL_KEY = data.client_secret.value;

    // Create a peer connection
    const pc = new RTCPeerConnection();

    // Set up to play remote audio from the model
    audioElement.current = document.createElement("audio");
    audioElement.current.autoplay = true;
    pc.ontrack = (e) => (audioElement.current.srcObject = e.streams[0]);

    // Add local audio track for microphone input in the browser
    const ms = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    pc.addTrack(ms.getTracks()[0]);

    // Set up data channel for sending and receiving events
    const dc = pc.createDataChannel("oai-events");
    setDataChannel(dc);

    // Start the session using the Session Description Protocol (SDP)
    const offer = await pc.createOffer();
    await pc.setLocalDescription(offer);

    const baseUrl = "https://api.openai.com/v1/realtime";
    const model = "gpt-4o-realtime-preview-2024-12-17";
    const sdpResponse = await fetch(`${baseUrl}?model=${model}`, {
      method: "POST",
      body: offer.sdp,
      headers: {
        Authorization: `Bearer ${EPHEMERAL_KEY}`,
        "Content-Type": "application/sdp",
      },
    });

    const answer = {
      type: "answer",
      sdp: await sdpResponse.text(),
    };
    await pc.setRemoteDescription(answer);

    peerConnection.current = pc;
  }

  // Stop current session, clean up peer connection and data channel
  function stopSession() {
    if (dataChannel) {
      dataChannel.close();
    }
    if (peerConnection.current) {
      peerConnection.current.close();
    }

    setIsSessionActive(false);
    setDataChannel(null);
    peerConnection.current = null;
  }

  // Send a message to the model
  function sendClientEvent(message) {
    if (dataChannel) {
      message.event_id = message.event_id || crypto.randomUUID();
      dataChannel.send(JSON.stringify(message));
      setEvents((prev) => [message, ...prev]);
    } else {
      console.error(
        "Failed to send message - no data channel available",
        message
      );
    }
  }

  // Send a text message to the model
  function sendTextMessage(message) {
    const event = {
      type: "conversation.item.create",
      item: {
        type: "message",
        role: "user",
        content: [
          {
            type: "input_text",
            text: message,
          },
        ],
      },
    };

    sendClientEvent(event);
    sendClientEvent({ type: "response.create" });
  }

  // Attach event listeners to the data channel when a new one is created
  useEffect(() => {
    if (dataChannel) {
      dataChannel.addEventListener("message", (e) => {
        setEvents((prev) => [JSON.parse(e.data), ...prev]);
      });

      dataChannel.addEventListener("open", () => {
        setIsSessionActive(true);
        setEvents([]);
      });
    }
  }, [dataChannel]);

  useEffect(() => {
    if (!events || events.length === 0) return;

    const firstEvent = events[events.length - 1];
    if (!functionAdded && firstEvent.type === "session.created") {
      updateSession();
    }

    const mostRecentEvent = events[0];
    if (
      mostRecentEvent.type === "response.done" &&
      mostRecentEvent.response.output
    ) {
      mostRecentEvent.response.output.forEach(async (output) => {
        if (
          output.type === "function_call" &&
          output.name === "tavily_search"
        ) {
          const res = await tavily_search(JSON.parse(output.arguments).query);
          sendClientEvent({
            type: "conversation.item.create",
            item: {
              type: "function_call_output",
              call_id: output.call_id,
              output: res,
            },
          });
          sendClientEvent({ type: "response.create" });
        }
        if (output.type === "function_call" && output.name === "search") {
          const res = await praxisAgent(JSON.parse(output.arguments).query);
          sendClientEvent({
            type: "conversation.item.create",
            item: {
              type: "function_call_output",
              call_id: output.call_id,
              output: res,
            },
          });
          sendClientEvent({ type: "response.create" });
        }
      });
    }
  }, [events]);

  useEffect(() => {
    if (!isSessionActive) {
      setFunctionAdded(false);
    }
  }, [isSessionActive]);

  const updateSession = () => {
    let instructions = "";
    let funcObj = {};
    if (
      myFiles.length > 0 ||
      !["default", "meetingAssistant", "UX"].includes(typeOfAg)
    ) {
      instructions = `You are a helpful assistant.
      For all query or question, always use the 'search' tool.
      Assume the user has already provided a document. For any document-related question, proceed directly to use the search tool on the document to retrieve information. Avoid requesting the user to re-upload or confirm the document's presence.
      The user is listening to answers with audio, so please make sure to respond with a helpful voice via audio.`;
      funcObj = {
        type: "function",
        name: "search",
        description: `For all query or question.`,
        parameters: {
          type: "object",
          strict: true,
          properties: {
            query: {
              type: "string",
              description:
                "Users, as it is a full search query. Do not modify or interpret the original query.",
            },
          },
          required: ["query"],
        },
      };
    } else {
      instructions = `System settings:
      Tool use: enabled.
      
      Instructions:
      - You are an artificial intelligence agent responsible for helping realtime voice capabilities
      - Please make sure to respond with a helpful voice via audio
      - Be kind, helpful, and curteous
      - It is okay to ask the user questions
      - Use tools and functions you have available liberally, it is part of the training apparatus
      - Be open to exploration and conversation
      - Response must in english language only.
      - Always use the 'tavily_search' tool to Get information on recent events from the web.
      
      Personality:
      - Be upbeat and genuine
      - Try speaking quickly as if excited`;
      funcObj = {
        type: "function",
        name: "tavily_search",
        description: "Get information on recent events from the web.",
        parameters: {
          type: "object",
          strict: true,
          properties: {
            query: {
              type: "string",
              description:
                "The search query to use. For example: 'Latest news on Nvidia stock performance'.",
            },
          },
          required: ["query"],
        },
      };
    }

    sendClientEvent({
      type: "session.update",
      session: {
        instructions: instructions,
        tool_choice: "auto",
        tools: [funcObj],
      },
    });
    setFunctionAdded(true);
    sendTextMessage("Hello");
  };

  const handleClose = () => {
    allowSleep();
    stopSession();
    setIsSessionActive(false);
    setIsVoiceAgent(false);
  };

  const praxisAgent = async (query) => {
    const formData = new FormData();
    formData.append("query", query);
    formData.append("AImodel", JSON.stringify(selectedAIModel));
    formData.append("vectorTypeDB", vectorTypeDB);
    formData.append("userId", user.sub);
    formData.append("email", user.email);
    formData.append("temperature", temperature);
    formData.append("isRFormate", isRFormate);
    formData.append("typeOfAg", typeOfAg);
    formData.append("pipelineTypeDoc", pipelineTypeDoc);
    formData.append("flowPipeline", flowPipeline);
    formData.append("isFile", myFiles.length > 0);
    formData.append("conversation", JSON.stringify([]));
    // formData.append("threadId", newThreadId);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/chat`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const output = data?.response;
    return output;
  };

  const tavily_search = async (query) => {
    const { data } = await axios.post(`https://api.tavily.com/search`, {
      api_key: "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",
      query: query,
    });
    const output = data.results;
    return JSON.stringify(output);
  };

  return (
    <>
      <Dialog onClose={handleClose} open={isVoiceAgent} fullWidth maxWidth="sm">
        {/* <DialogTitle>Speech to speech agent</DialogTitle> */}
        <Box
          sx={{
            p: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <AudioRipple isSessionActive={isSessionActive} />

          <IconButton onClick={handleClose}>
            <StopCircleIcon sx={{ fontSize: "60px" }} />
          </IconButton>
        </Box>
      </Dialog>
    </>
  );
};

export default VoiceRecorder;

const AudioRipple = ({ isSessionActive }) => {
  return (
    <Box sx={{ my: 5 }}>
      <Box className={`ripple-container`}>
        <Box className={`ripple ${isSessionActive && "animate"}`}></Box>
        <Box className={`ripple ${isSessionActive && "animate"}`}></Box>
        <Box className={`ripple ${isSessionActive && "animate"}`}></Box>
      </Box>
    </Box>
  );
};
