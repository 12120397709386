import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  ChooseFilename: {
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "nowrap",
      width: "200px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      paddingRight: "30px",
    },
  },
}));

const FileList = ({ file, setMyFilesState }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: { xs: "0px 15px 0px 30px", md: "0px 0px 0px 30px" },
        maxWidth: {
          xs: "450px",
          sm: "540px",
          md: "800px",
        },
        margin: "0 auto",
      }}
    >
      <li className={classes.ChooseFilename} key={file.path}>
        {file.path}
      </li>
      <Box display="flex" sx={{ ml: { xs: 0, sm: 0, md: 50 } }}>
        <IconButton
          style={{ color: "red" }}
          aria-label="delete"
          onClick={() => {
            setOpen(true);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Dialog key={file} open={open} onClose={handleClose}>
        <Box
          sx={{
            padding: "20px",
          }}
        >
          <DialogTitle
            sx={{ fontSize: "20px", fontWeight: 500, padding: "16px 0px" }}
          >
            Remove file
          </DialogTitle>
          <DialogContentText>
            <Typography>
              Would you like to
              <span key={file.path}>{file.path}</span>
              remove ?
            </Typography>
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleClose} sx={{ textTransform: "capitalize" }}>
              Cancel
            </Button>
            <Button
              onClick={() => setMyFilesState(file)}
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: "0.4px",
                color: "rgba(211, 47, 47, 1)",
                textTransform: "capitalize",
              }}
            >
              Remove file
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default FileList;
