import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Box, Paper, Grid } from "@mui/material";
import { toast } from "react-toastify";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import "react-toastify/dist/ReactToastify.css";
import {
  setAgentList,
  addItemChatHistory,
  setDrawer,
  setIsAgentDropdownOpen,
  setIsChatIcon,
  setIsClearChatData,
  setIsDashboardDropdownOpen,
  setMyFiles,
  setSelectedAIModel,
  setTemperature,
  setTypeOfAg,
  setVectorTypeDB,
  setAutoScroll,
} from "../redux/reducer";
import FileUpload from "../Components/Dashboard/FileUpload";
import MicRecord from "../Components/MicRecord";
import { ChatHeader, ChatTextField } from "../Components/Dashboard/Chat";
import UpdateGivenName from "../Components/Auth/UpdateGivenName";
import ListMessages from "../Components/Dashboard/ListMessages";
import { AIModelList } from "../Components/AIModelSelect";

const ChatBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 0,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

let controller;

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = useLocation();
  const chatRef = useRef(null);
  const lastQuestionRef = useRef(null);
  const lastMessageRef = useRef(null);
  const [tabValue, setTabValue] = useState(0);
  const [isVoiceAgent, setIsVoiceAgent] = useState(false);
  const [lastTyping, setLastTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showBottomBtn, setShowBottomBtn] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [values, setValues] = useState({ query: "", remote_url: "" });

  const chatHistory = useSelector((state) => state.chatHistory);
  const myFiles = useSelector((state) => state.myFiles);
  const isClearChatData = useSelector((state) => state.isClearChatData);
  const selectedAIModel = useSelector((state) => state.selectedAIModel);
  const vectorTypeDB = useSelector((state) => state.vectorTypeDB);
  const user = useSelector((state) => state.user);
  const isRFormate = useSelector((state) => state.isRFormate);
  const temperature = useSelector((state) => state.temperature);
  const pipelineTypeDoc = useSelector((state) => state.pipelineTypeDoc);
  const typeOfAg = useSelector((state) => state.typeOfAg);
  const flowPipeline = useSelector((state) => state.flowPipeline);
  const isAutoScroll = useSelector((state) => state.isAutoScroll);

  const queryParams = new URLSearchParams(location.search);
  const threadId = queryParams.get("threadId");
  const matchingData = chatHistory?.[threadId] || [];

  const handleScroll = () => {
    if (!chatRef.current) return;
    const isAtBottom =
      Math.abs(
        chatRef.current.scrollHeight -
          chatRef.current.clientHeight -
          chatRef.current.scrollTop
      ) < 1;

    setShowBottomBtn(!isAtBottom);
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPressShortcut);

    const currentChatRef = chatRef.current;
    if (currentChatRef) {
      currentChatRef.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (currentChatRef) {
        currentChatRef.removeEventListener("scroll", handleScroll);
        document.removeEventListener("keydown", handleKeyPressShortcut);
      }
    };
  }, []);

  useEffect(() => {
    if (matchingData.length > 0) {
      const config = matchingData[matchingData.length - 1]?.config;

      if (config) {
        dispatch(
          setSelectedAIModel(
            AIModelList.find((item) => item.model === config.AImodel)
          )
        );
        dispatch(setTypeOfAg(config?.typeOfAg));
        dispatch(setVectorTypeDB(config.vectorTypeDB));
        dispatch(setTemperature(config.temperature));
      }
    }
  }, [matchingData, dispatch]);

  useEffect(() => {
    if (user?.sub) {
      axios.post(`${process.env.REACT_APP_API_URL}/api/chat/clearSession`, {
        userId: user.sub,
        typeOfAg: typeOfAg,
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/agentList/${user.email}`)
        .then(({ data }) => {
          dispatch(setAgentList(data));
        });
    }

    if (chatRef.current)
      chatRef.current.scrollTop = -chatRef.current.scrollHeight;
  }, [user]);

  useEffect(() => {
    if (values.query) {
      autoScroll();
      dispatch(setIsChatIcon(false));
    } else {
      dispatch(setIsChatIcon(true));
    }
  }, [values]);

  useEffect(() => {
    if (state?.tabValue !== undefined) setTabValue(state?.tabValue);
  }, [state]);

  useEffect(() => {
    if (isClearChatData) {
      dispatch(setMyFiles([]));
      setValues({ query: "", remote_url: "" });
      setLoading(false);
      dispatch(setIsClearChatData(false));
      setShowBottomBtn(false);
    }
  }, [isClearChatData]);

  useEffect(() => {
    let interval;
    const maxScroll =
      window.innerHeight -
      lastQuestionRef?.current?.getBoundingClientRect().height -
      270;
    if (lastTyping) {
      const initialScrollPosition = chatRef.current?.scrollTop || 0;
      interval = setInterval(() => {
        handleRealScroll(initialScrollPosition, maxScroll);
      }, 100);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [lastTyping]);

  useEffect(() => {
    if (isAutoScroll) {
      setTimeout(() => {
        autoScroll();
      }, 300);
    }
  }, [matchingData]);

  const handleRealScroll = (initialScrollPosition, maxScroll) => {
    if (!chatRef.current) return;
    const currentScroll = chatRef.current.scrollTop;
    const additionalScroll = currentScroll - initialScrollPosition;
    if (additionalScroll < maxScroll) {
      chatRef.current.scrollTo({
        top: Math.min(
          chatRef.current.scrollHeight,
          initialScrollPosition + maxScroll
        ),
        behavior: "smooth",
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleKeyPressShortcut = (e) => {
    if (e.ctrlKey && e.key === "f") {
      e.preventDefault();
      handleChange(e, 1);
    } else if (e.ctrlKey && e.key === "a") {
      e.preventDefault();
      dispatch(setDrawer(true));
      setTimeout(() => {
        dispatch(setIsAgentDropdownOpen(true));
      }, 500);
    } else if (e.ctrlKey && e.key === "m") {
      e.preventDefault();
      dispatch(setIsDashboardDropdownOpen(true));
    }
  };

  const handleSendMessage = async () => {
    let query = values.query.trim();
    let fthreadId = "";
    let newMessage = {};
    if (tabValue === 0 && !query) {
      return toast.error("Please add prompts!");
    }

    try {
      setTimeout(() => {
        autoScroll();
      }, 100);
      setLoading(true);
      const newThreadId = uuidv4();
      fthreadId = threadId ? threadId : newThreadId;

      newMessage = {
        id: Date.now(),
        question: query,
        created_at: Date.now(),
        email: user?.email,
        userId: user?.sub,
        threadTitle: query,
        threadId: fthreadId,
        config: {
          temperature: temperature,
          vectorTypeDB: vectorTypeDB,
          typeOfAg: typeOfAg,
          AImodel: selectedAIModel.model,
          AIprovider: selectedAIModel.provider,
        },
      };
      dispatch(
        addItemChatHistory({
          key: fthreadId,
          newItem: newMessage,
        })
      );
      if (!threadId) navigate(`/dashboard?threadId=${fthreadId}`);

      setValues({ ...values, query: "" });

      const formData = new FormData();
      formData.append("query", query);
      formData.append("AImodel", JSON.stringify(selectedAIModel));
      formData.append("vectorTypeDB", vectorTypeDB);
      formData.append("userId", user.sub);
      formData.append("email", user.email);
      formData.append("temperature", temperature);
      formData.append("isRFormate", isRFormate);
      formData.append(
        "typeOfAg",
        typeOfAg === "PiP SuperChat" ? "MUFG SuperChat" : typeOfAg
      );
      formData.append("pipelineTypeDoc", pipelineTypeDoc);
      formData.append("flowPipeline", flowPipeline);
      formData.append("isFile", myFiles.length > 0);
      formData.append("threadId", fthreadId);

      if (values.remote_url) formData.append("remote_url", values.remote_url);

      if (matchingData?.length > 0) {
        const conversation = matchingData
          .slice(-4)
          .map((item) => {
            return {
              question: item.question,
              answer: item.answer || "",
            };
          })
          .filter((item) => item !== null);

        formData.append("conversation", JSON.stringify(conversation));
      }
      controller = new AbortController();
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/chat`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          signal: controller.signal,
        }
      );

      const resMessage = { ...res.data?.response, shouldType: true };

      dispatch(
        addItemChatHistory({
          key: fthreadId,
          newItem: resMessage,
          isRemoveId: newMessage.id,
        })
      );

      // dispatch(fetchUserChats());

      setLoading(false);
      dispatch(setSelectedAIModel(selectedAIModel));
      dispatch(setTypeOfAg(typeOfAg));
      dispatch(setAutoScroll(false));
      setTimeout(() => {
        newMessageAdd();
      }, 300);
    } catch (error) {
      setLoading(false);
      console.error("Error catch message:", error);
      dispatch(
        addItemChatHistory({
          key: fthreadId,
          isRemoveId: newMessage.id,
          newItem: {
            id: Date.now(),
            question: query,
            answer: `I’m sorry, ${
              user.given_name.split(" ")[0]
            }. I’m afraid I can’t do that right now`,
            email: user?.email,
            userId: user?.sub,
            threadTitle: query,
            threadId: fthreadId,
            created_at: Date.now(),
            config: newMessage.config,
          },
        })
      );
    }
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleStop = () => {
    controller.abort();
    setLoading(false);
  };

  const autoScroll = () => {
    if (!chatRef.current) return;
    chatRef.current.scrollTo({
      top: chatRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  const newMessageAdd = () => {
    const scrollHeight =
      chatRef.current.scrollHeight - lastMessageRef.current.scrollHeight - 80;
    chatRef.current.scrollTo({
      top: scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <Box
      sx={{
        mb: { xs: 0, sm: 4, md: 5 },
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Paper
            elevation={4}
            sx={{
              border: "none",
              boxShadow: "none",
            }}
          >
            <TabPanel value={tabValue} index={0}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  height: { xs: "88vh", sm: "95vh", md: "98vh" },
                  position: "relative",
                }}
              >
                <ChatBox
                  sx={{
                    height: {
                      xs: "85vh",
                      sm: "88vh",
                      md: "88vh",
                    },
                    justifyContent:
                      myFiles.length === 0 && chatHistory?.length === 0
                        ? "center"
                        : "flex-start",
                  }}
                  ref={chatRef}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      paddingTop: "75px",
                      height: "80vh",
                    }}
                  >
                    <ChatHeader matchingData={matchingData} myFiles={myFiles} />
                    <ListMessages
                      matchingData={matchingData}
                      lastMessageRef={lastMessageRef}
                      lastQuestionRef={lastQuestionRef}
                      loading={loading}
                      setOpenPopup={setOpenPopup}
                      openPopup={openPopup}
                      setLastTyping={setLastTyping}
                    />
                    {showBottomBtn && (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 100,
                          left: "50%",
                          transform: "translate(-50%, 0)",
                          cursor: "pointer",
                          zIndex: 20,
                        }}
                      >
                        <ArrowDownwardIcon
                          style={{
                            color: "black",
                            fontSize: "22px",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            padding: "4px",
                          }}
                          onClick={() => autoScroll()}
                        />
                      </Box>
                    )}
                  </Box>
                </ChatBox>

                <ChatTextField
                  values={values}
                  setValues={setValues}
                  handleKeyPress={handleKeyPress}
                  setIsVoiceAgent={setIsVoiceAgent}
                  handleStop={handleStop}
                  loading={loading}
                  handleSendMessage={handleSendMessage}
                  handleChange={handleChange}
                  autoScroll={autoScroll}
                />
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <FileUpload
                setValues={setValues}
                myFiles={myFiles}
                handleChange={handleChange}
                setTabValue={setTabValue}
                values={values}
                chatHistoryLength={chatHistory?.length}
              />
            </TabPanel>
          </Paper>
        </Box>
        {isVoiceAgent && (
          <MicRecord
            isVoiceAgent={isVoiceAgent}
            setIsVoiceAgent={setIsVoiceAgent}
            myFiles={myFiles}
          />
        )}
        <UpdateGivenName />
      </Grid>
    </Box>
  );
};

export default Dashboard;
