import React from "react";
import { Tooltip, IconButton, Box } from "@mui/material";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";

const DownloadTooltip = ({
  messageText,
  index,
  setOpenPopup,
  handleFileDownload,
}) => {
  const user = useSelector((state) => state.user);

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <Tooltip title="Download as PDF" arrow placement="bottom">
        <IconButton
          onClick={() => handleFileDownload(messageText, "pdf", index)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            "&:hover": { color: "black" },
          }}
        >
          <Icon icon="mdi:file-pdf-box" width={24} height={24} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Download as DOCX" arrow placement="bottom">
        <IconButton
          onClick={() => handleFileDownload(messageText, "docx", index)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            "&:hover": { color: "black" },
          }}
        >
          <Icon icon="mdi:file-word" width={24} height={24} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Download as PPTX" arrow placement="bottom">
        <IconButton
          onClick={() => handleFileDownload(messageText, "pptx", index)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            "&:hover": { color: "black" },
          }}
        >
          <Icon icon="mdi:file-powerpoint" width={24} height={24} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Send" arrow placement="bottom">
        <IconButton
          onClick={() => setOpenPopup(true)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            "&:hover": { color: "black" },
          }}
        >
          <Icon icon="mdi:send" width={24} height={24} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Copy Text" arrow placement="bottom">
        <IconButton
          onClick={() => navigator?.clipboard?.writeText(messageText)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            "&:hover": { color: "black" },
          }}
        >
          <Icon icon="mdi:clipboard-text" width={24} height={24} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default DownloadTooltip;
