import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AIModelList } from "../Components/AIModelSelect";

const initialState = {
  user: {},
  isSettingEnabled: false,
  isClearChatData: false,
  myFileList: 0,
  isTyping: false,
  selectedAIModel: AIModelList[2],
  vectorTypeDB: "chromadb",
  isNewChatModel: false,
  isRFormate: false,
  temperature: 0.7,
  chatHistory: [],
  pipelineTypeDoc: "openaiFile",
  typeOfAg: "default",
  flowPipeline: "langGraph",
  agentList: [],
  isChatIcon: true,
  isTypingEffect: true,
  drawer: false,
  isDropdownOpen: false,
  isDashboardDropdownOpen: false,
  isAgentDropdownOpen: false,
  sidebarClose: false,
  myFiles: [],
  isAutoScroll: true,
};

export const rootReducerSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setIsSettingEnabled: (state, { payload }) => {
      state.isSettingEnabled = payload;
    },
    setIsClearChatData: (state, { payload }) => {
      state.isClearChatData = payload;
    },
    setMyFileList: (state, { payload }) => {
      state.myFileList = payload;
    },
    setIsTypingFN: (state, { payload }) => {
      state.isTyping = payload;
    },
    setSelectedAIModel: (state, { payload }) => {
      state.selectedAIModel = payload;
    },
    setIsNewChatModel: (state, { payload }) => {
      state.isNewChatModel = payload;
    },
    setVectorTypeDB: (state, { payload }) => {
      state.vectorTypeDB = payload;
    },
    setIsRFormate: (state, { payload }) => {
      state.isRFormate = payload;
    },
    setTypeOfAg: (state, { payload }) => {
      state.typeOfAg = payload;
    },
    setTemperature: (state, { payload }) => {
      state.temperature = payload;
    },
    addItemChatHistory: (state, { payload }) => {
      const { key, newItem, isRemoveId = false } = payload;
      if (state.chatHistory[key]) {
        if (isRemoveId) {
          state.chatHistory[key] = state.chatHistory[key].filter(
            (item) => item.id !== isRemoveId
          );
        }

        state.chatHistory[key].push(newItem);
      } else {
        state.chatHistory = {
          [key]: [newItem],
          ...state.chatHistory,
        };
      }
    },
    updateChatMessage: (state, { payload }) => {
      const { threadId, chatId } = payload;
      state.chatHistory = {
        ...state.chatHistory,
        [threadId]: state.chatHistory[threadId]?.map((ele) =>
          ele.id === chatId ? { ...ele, shouldType: false } : ele
        ),
      };
    },
    setPipelineTypeDoc: (state, { payload }) => {
      state.pipelineTypeDoc = payload;
    },
    setFlowPipeline: (state, { payload }) => {
      state.flowPipeline = payload;
    },
    setAgentList: (state, { payload }) => {
      state.agentList = payload;
    },
    setIsChatIcon: (state, { payload }) => {
      state.isChatIcon = payload;
    },
    setIsTypingEffect: (state, { payload }) => {
      state.isTypingEffect = payload;
    },
    setDrawer: (state, { payload }) => {
      state.drawer = payload;
    },
    setIsDropdownOpen: (state, { payload }) => {
      state.isDropdownOpen = payload;
    },
    setIsDashboardDropdownOpen: (state, { payload }) => {
      state.isDashboardDropdownOpen = payload;
    },
    setIsAgentDropdownOpen: (state, { payload }) => {
      state.isAgentDropdownOpen = payload;
    },
    setSidebarClose: (state, { payload }) => {
      state.sidebarClose = payload;
    },
    setMyFiles: (state, { payload }) => {
      state.myFiles = payload;
    },
    setAutoScroll: (state, { payload }) => {
      state.isAutoScroll = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserChats.fulfilled, (state, action) => {
      state.chatHistory = action.payload;
    });
  },
});

export const fetchUserChats = createAsyncThunk(
  "users/fetchUserChats",
  async (_, { getState }) => {
    const { user } = getState();
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/users_chat_history?userId=${user?.sub}`
    );
    return response.data;
  }
);

// Action creators are generated for each case reducer function
export const {
  setUser,
  setIsSettingEnabled,
  setIsClearChatData,
  setMyFileList,
  setIsTypingFN,
  setSelectedAIModel,
  setIsNewChatModel,
  setVectorTypeDB,
  setIsRFormate,
  setTemperature,
  addItemChatHistory,
  updateChatMessage,
  setPipelineTypeDoc,
  setTypeOfAg,
  setFlowPipeline,
  setAgentList,
  setIsChatIcon,
  setIsTypingEffect,
  setIsModelOpen,
  setDrawer,
  setIsDropdownOpen,
  setIsDashboardDropdownOpen,
  setIsAgentDropdownOpen,
  setSidebarClose,
  setMyFiles,
  setAutoScroll,
} = rootReducerSlice.actions;

export default rootReducerSlice.reducer;
