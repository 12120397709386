import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import remarkGfm from "remark-gfm";
import { updateChatMessage } from "../../redux/reducer";

const TypingText = ({ markdownContent, setLastTyping, shouldType }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [visibleCharacters, setVisibleCharacters] = useState(0);

  const queryParams = new URLSearchParams(location.search);
  const threadId = queryParams.get("threadId");

  const chatHistory = useSelector((state) => state.chatHistory);
  const isTypingEffect = useSelector((state) => state.isTypingEffect);

  useEffect(() => {
    let timeoutId;
    const typeNextCharacter = () => {
      if (isTypingEffect && visibleCharacters < markdownContent?.length) {
        timeoutId = setTimeout(
          () => {
            setVisibleCharacters((prevVisibleChars) => prevVisibleChars + 10);
            typeNextCharacter();
          },
          isTypingEffect ? 80 : 1
        );
        setLastTyping(true);
      } else {
        const lastData = chatHistory[threadId];

        const lastMessage = lastData[lastData?.length - 1];
        const chatId = lastMessage?.id;

        dispatch(updateChatMessage({ threadId, chatId }));
        setLastTyping(false);
        setVisibleCharacters(markdownContent?.length);
      }
    };
    typeNextCharacter();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [markdownContent, visibleCharacters]);

  // const typingText = markdownContent.substring(0, visibleCharacters);

  let typingText = "";
  if (shouldType && isTypingEffect) {
    typingText = markdownContent.substring(0, visibleCharacters);
  } else {
    typingText = markdownContent;
  }

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      children={typingText}
      components={{
        a: (props) => (
          <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
          </a>
        ),
      }}
    />
  );
};

export default TypingText;
