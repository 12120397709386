import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Typography,
  Box,
  Button,
  LinearProgress,
  IconButton,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import {
  setChatHistory,
  setIsNewChatModel,
  setMyFileList,
  setMyFiles,
} from "../../redux/reducer";
import FileList from "./FileList";
import { remove, uploadData } from "aws-amplify/storage";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StartNewChat from "../StartNewChat";
import { useLocation, useNavigate } from "react-router-dom";

const audioFilesTypes = [
  "audio/wav",
  "audio/mpeg",
  "audio/ogg",
  "audio/x-m4a",
  "video/mp4",
  "video/mpeg",
  "video/webm",
];

function FileUpload({
  setValues,
  handleChange,
  setTabValue,
  values,
  chatHistoryLength,
}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const myFiles = useSelector((state) => state.myFiles);
  const user = useSelector((state) => state.user);
  const pipelineTypeDoc = useSelector((state) => state.pipelineTypeDoc);
  const typeOfAg = useSelector((state) => state.typeOfAg);
  const selectedAIModel = useSelector((state) => state.selectedAIModel);
  // const chatHistory = useSelector((state) => state.chatHistory);
  const uuid = uuidv4();
  // const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const threadId = queryParams.get("threadId");

  const onDrop = useCallback(
    (acceptedFiles) => {
      dispatch(setMyFiles([...acceptedFiles]));
      setValues({ ...values, remote_url: "" });
    },
    [myFiles]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "text/plain": [".txt"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [".xlsx"],
      "application/json": [".json"],
      ...(selectedAIModel?.allowImage && {
        "image/png": [".png"],
        "image/jpeg": [".jpeg"],
        "image/jpg": [".jpg"],
        "image/heic": [".heic"],
      }),
      "audio/*": [
        ".mp3",
        ".mp4",
        ".mpeg",
        ".mpga",
        ".m4a",
        ".wav",
        ".ogg",
        ".webm",
      ],
    },
    multiple: true,
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    if (myFiles.length > 0) {
      dispatch(setMyFileList(1));
    } else {
      dispatch(setMyFileList(0));
    }
  }, [myFiles]);

  // const handleFileUpload = () => {
  //   if (myFiles?.length === 0 && !values.remote_url) {
  //     return toast.error("Please choose PDF file or provide remote url");
  //   } else {
  //     toast.success("File or remote url upload successfully");
  //     handleChange(null, 0);
  //   }
  // };

  const handleOpen = () => {
    // if (chatHistoryLength) {
    if (threadId) {
      dispatch(setIsNewChatModel(true));
    } else {
      open();
    }
    // } else {
    //   open();
    // }
  };

  const uploadFile = async (acceptedFiles) => {
    try {
      setIsLoading(true);
      // const newThreadId = uuid;

      // const fileUploadMessage = {
      //   id: Date.now(),
      //   question: `Uploaded ${acceptedFiles.length} file(s): ${acceptedFiles
      //     .map((file) => file.name)
      //     .join(", ")}`,
      //   threadId: newThreadId,
      //   userId: user?.sub,
      //   email: user?.email,
      // };

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/chat/clearSession`,
        { userId: user.sub, typeOfAg: typeOfAg }
      );

      await Promise.all(
        acceptedFiles.map(
          async (file) =>
            await uploadData({
              path: `public/${user.sub}/${file.name}`,
              data: file,
              options: {
                contentType: file.type,
              },
            }).result
        )
      );

      const ingoreFileopenAI = acceptedFiles.filter((file) =>
        [
          "image/png",
          "image/jpeg",
          "image/heic",
          "text/csv",
          "application/vnd.ms-excel",
        ].includes(file.type)
      ).length;

      if (!ingoreFileopenAI && pipelineTypeDoc === "openaiFile") {
        const formData = new FormData();
        formData.append("userId", user.sub);
        acceptedFiles.forEach((file) => formData.append("file", file));
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/uploadMultiple`,
          formData
        );
      }

      // const systemResponse = {
      //   id: Date.now() + 1,
      //   question: "Files uploaded successfully. How can I help you analyze these documents?",
      //   threadId: newThreadId,
      //   userId: user?.sub,
      //   email: user?.email,
      // };

      // if (threadId) {
      //   const updatedChatHistory = {
      //     ...chatHistory,
      //     [threadId]: [...(chatHistory[threadId] || []), fileUploadMessage, systemResponse],
      //   };
      //   dispatch(setChatHistory(updatedChatHistory));
      //   setMatchingData([...matchingData, fileUploadMessage, systemResponse]);
      // } else {

      // dispatch(
      //   setChatHistory({
      //     [newThreadId]: [fileUploadMessage],
      //     ...chatHistory,
      //   })
      // );
      // setIsLoading(false);
      // const targetThreadId = threadId || newThreadId;
      // navigate(`/dashboard?threadId=${targetThreadId}`);

      // return targetThreadId;
    } catch (error) {
      console.error("Error uploading files:", error);
      setIsLoading(false);
      toast.error("Something went wrong while uploading files");

      // const errorMessage = {
      //   id: Date.now(),
      //   question: `Error uploading files: ${error.message}`,
      // };

      // if (threadId) {
      //   const updatedChatHistory = {
      //     ...chatHistory,
      //     [threadId]: [...(chatHistory[threadId] || []), errorMessage],
      //   };
      //   dispatch(setChatHistory(updatedChatHistory));
      // }

      // throw error;
    }
  };

  const speechToTextSubmit = async (file) => {
    try {
      setIsLoading(true);
      const formData = new FormData();

      formData.append("file", file);
      formData.append("email", user.email);

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/speechToText`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success(res?.data?.response);
      setIsLoading(false);
      dispatch(setMyFiles([]));
    } catch (error) {
      setIsLoading(false);
      console.error("Error catch message:", error);
      toast.error(
        error?.response?.data?.error || error.message || "Something went wrong"
      );
      dispatch(setMyFiles([]));
    }
  };

  const handleSubmit = async () => {
    if (isAudioFile) {
      await speechToTextSubmit(myFiles[0]);
    } else {
      await uploadFile(myFiles);
    }
    setTabValue(0);
  };

  const isAudioFile = audioFilesTypes.includes(myFiles[0]?.type);

  return (
    <Box
      sx={{
        height: "100vh",
        p: { xs: 3, sx: 4, md: 5 },
        mt: 10,
        maxWidth: {
          xs: "450px",
          sm: "540px",
          md: "800px",
        },
        margin: "0 auto",
      }}
    >
      <Box>
        <Box mb={5} mt={10} sx={{ position: "relative" }}>
          <Box sx={{ position: "absolute" }}>
            <IconButton onClick={() => setTabValue(0)}>
              <ArrowBackIcon />
            </IconButton>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 400,
                textAlign: "center",
                ml: "auto",
                mr: "auto",
              }}
            >
              Sources
            </Typography>
          </Box>
        </Box>
        <Box
          {...getRootProps({ className: "dropzone" })}
          sx={{
            "&:hover": {
              border: "1px solid rgba(0, 0, 0, 0.50)",
            },
            padding: { xs: "10px 0", sm: "15px 0", md: "20px 0" },
            cursor: "pointer",
            textAlign: "center",
          }}
          onClick={handleOpen}
        >
          <input {...getInputProps()} />
          <Icon icon="tabler:upload" />

          <Typography mt={1} whiteSpace="pre-line">
            {isLoading ? (
              isAudioFile ? (
                `Transcribing.. \n If you upload an audio file then we'll transcribe it and send the transcription to your email.`
              ) : (
                `Uploading...`
              )
            ) : (
              <>
                Drag and drop or&nbsp;
                <span
                  style={{
                    color: "rgba(5, 87, 250, 1)",
                    textDecorationLine: "underline",
                  }}
                >
                  Choose file
                </span>
                &nbsp; to upload
                <br />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 0.60)",
                  }}
                  component="span"
                >
                  PDF, CSV, Excel, Images, Text, Docs audio only supported
                </Typography>
              </>
            )}
          </Typography>
        </Box>
        {isLoading && <LinearProgress />}
        <aside>
          <ul
            style={{
              paddingLeft: "0px",
              overflow: "auto",
              maxHeight: "140px",
            }}
          >
            {myFiles.map((file) => (
              <FileList
                file={file}
                setMyFilesState={async (file) => {
                  if (myFiles?.length >= 2)
                    await remove({ path: `public/${user.sub}/${file.name}` });
                  dispatch(
                    setMyFiles(
                      myFiles.filter((item) => item.name !== file.name)
                    )
                  );
                }}
              />
            ))}
          </ul>
        </aside>
        {myFiles.length > 0 && (
          <Box sx={{ textAlign: "end" }}>
            <Button
              variant="contained"
              disabled={isLoading}
              onClick={() => handleSubmit()}
            >
              {isAudioFile ? "Upload" : "Done"}
            </Button>
          </Box>
        )}
      </Box>
      {/* <Divider
          sx={{
            marginTop: 4,
          }}
        >
          <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>OR</Typography>
        </Divider>
        <Box
          sx={{
            margin: "0 auto",
            marginBottom: "16px",
            marginTop: "16px",
          }}
        >
          <FormLabel
            sx={{
              display: "flex",
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            Import PDF from URL
          </FormLabel>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Add file URL"
              onBlur={() => values?.remote_url && setMyFiles([])}
              value={values?.remote_url}
              onChange={(e) =>
                setValues({ ...values, remote_url: e.target.value })
              }
              sx={{
                marginTop: "8px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "4px",
                },
              }}
              size="small"
            />
            <Button
              sx={{
                "&:hover": {
                  backgroundColor:
                    values?.remote_url.length > 0
                      ? "rgba(0,0,0,0.6)"
                      : "rgba(5, 87, 250, 0.1)",
                },
                p: "7px 14px",
                ml: 2,
                marginTop: "8px",
                color:
                  values?.remote_url.length > 0
                    ? "#0557FA"
                    : "rgba(0, 0, 0, 0.54)",
                textTransform: "capitalize",
                border:
                  values?.remote_url.length > 0
                    ? "1px solid rgba(5, 87, 250, 0.5)"
                    : "1px solid rgba(0, 0, 0, 0.2)",
                fontWeight: 500,
                borderRadius: "4px",
              }}
              onClick={handleFileUpload}
            >
              Import
            </Button>
          </Box>
        </Box> */}
      <StartNewChat fnCall={() => open()} />
    </Box>
  );
}

export default FileUpload;
